import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
Vue.prototype.$bus = new Vue();
Vue.prototype.$axios = axios

import ElementUI from 'element-ui'; // 导入element-ui包
import 'element-ui/lib/theme-chalk/index.css'; // 导入相关样式
Vue.use(ElementUI);

Vue.prototype.$apiBaseUrl = 'https://open.mdtravel.cn/'  //  'https://localhost:5001/'   // 'http://192.168.0.229:5001/'  //    


Vue.prototype.$bocatripUrl = 'https://bocatrip.com/#/'
Vue.config.productionTip = false



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
