import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Tree/LoGin.vue'
import axios from "axios";

Vue.use(VueRouter)

axios.defaults.timeout = 180000 //超时时间 30秒

const routes = [
  {
    path: '/ApplyCheckIn',
    name: 'ApplyCheckIn',
    component: () => import('../views/Tree/ApplyCheckIn.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/',
    name: 'LoGin',
    component: () => import('../views/Tree/LoGin.vue')
  },
  // {
  //   path: '/TreeView',
  //   name: 'TreeView',
  //    
  //   // this generates a separate chunk (TreeView.[hash].js) for this route
  //     
  //   component: () => import(/* webpackChunkName: "TreeView" */ '../views/Tree/TreeView.vue')
  // }
  {
    path: '/MainMenu',
    name: 'MainMenu',
    component: () => import('../views/Tree/MainMenu.vue'),
    children: [
      {
        path: '/AttractionManagement',
        name: 'AttractionManagement',
        component: () => import('../views/DAMA/AttractionManagement.vue')
      },
      {
        path: '/AttractionAdd',
        name: 'AttractionAdd',
        component: () => import('../views/DAMA/AttractionAdd.vue')
      },
      {
        path: '/AttractionUpd',
        name: 'AttractionUpd',
        component: () => import('../views/DAMA/AttractionUpd.vue')
      },
      {
        path: '/CampsiteManagement',
        name: 'CampsiteManagement',
        component: () => import('../views/DAMA/CampsiteManagement.vue')
      },
      {
        path: '/VehicleManagement',
        name: 'VehicleManagement',
        component: () => import('../views/DAMA/VehicleManagement.vue')
      },
      {
        path: '/VehicleAdd',
        name: 'VehicleAdd',
        component: () => import('../views/DAMA/VehicleAdd.vue')
      },
      {
        path: '/VehicleUpd',
        name: 'VehicleUpd',
        component: () => import('../views/DAMA/VehicleUpd.vue')
      },
      {
        path: '/VehicleInfo',
        name: 'VehicleInfo',
        component: () => import('../views/DAMA/VehicleInfo.vue')
      },
      {
        path: '/CityManagement',
        name: 'CityManagement',
        component: () => import('../views/DAMA/CityManagement.vue')
      },
      {
        path: '/CampsiteAdd',
        name: 'CampsiteAdd',
        component: () => import('../views/DAMA/CampsiteAdd.vue')
      },
      {
        path: '/CampsiteUpd',
        name: 'CampsiteUpd',
        component: () => import('../views/DAMA/CampsiteUpd.vue')
      },
      {
        path: '/ItineraryManagement',
        name: 'ItineraryManagement',
        component: () => import('../views/Quotation/ItineraryManagement.vue')
      },
      {
        path: '/ItineraryThirdPartyManagement',
        name: 'ItineraryThirdPartyManagement',
        component: () => import('../views/Quotation/ItineraryThirdPartyManagement.vue')
      },
      {
        path: '/ItineraryAdd',
        name: 'ItineraryAdd',
        component: () => import('../views/Quotation/ItineraryAdd.vue')
      },
      {
        path: '/ItineraryUpd',
        name: 'ItineraryUpd',
        component: () => import('../views/Quotation/ItineraryUpd.vue')
      },
      {
        path: '/RouteAdd',
        name: 'RouteAdd',
        component: () => import('../views/Quotation/RouteAdd.vue')
      },
      {
        path: '/RouteUpd',
        name: 'RouteUpd',
        component: () => import('../views/Quotation/RouteUpd.vue')
      },
      {
        path: '/CheckOut',
        name: 'CheckOut',
        component: () => import('../views/Quotation/CheckOut.vue')
      },
      {
        path: '/VisaManagement',
        name: 'VisaManagement',
        component: () => import('../views/Quotation/VisaManagement.vue')
      },
      {
        path: '/DistPriceList',
        name: 'DistPriceList',
        component: () => import('../views/APIInterfaceManagement/DistPriceList.vue')
      },
      {
        path: '/DistCitysList',
        name: 'DistCitysList',
        component: () => import('../views/APIInterfaceManagement/DistCitysList.vue')
      },
      {
        path: '/DistAreasList',
        name: 'DistAreasList',
        component: () => import('../views/APIInterfaceManagement/DistAreasList.vue')
      },
      {
        path: '/DistStationList',
        name: 'DistStationList',
        component: () => import('../views/APIInterfaceManagement/DistStationList.vue')
      },
      {
        path: '/VisaProductDetails',
        name: 'VisaProductDetails',
        component: () => import('../views/Quotation/VisaProductDetails.vue')
      },   
      {
        path: '/VisaPro',
        name: 'VisaPro',
        component: () => import('../views/Quotation/VisaPro.vue')
      }, 
      
      {
        path: '/product-detail',
        name: 'product-detail',
        component: () => import('../views/Quotation/VisaProDetails.vue')
      }, 
      {
        path: '/VisaOrder',
        name: 'VisaOrder',
        component: () => import('../views/Quotation/VisaOrder.vue')
      },
      {
        path: '/CityeventManagement',
        name: 'CityeventManagement',
        component: () => import('../views/DAMA/CityeventManagement.vue')
      },
      {
        path: '/CityeventAdd',
        name: 'CityeventAdd',
        component: () => import('../views/DAMA/CityeventAdd.vue')
      },
      {
        path: '/CityeventUpd',
        name: 'CityeventUpd',
        component: () => import('../views/DAMA/CityeventUpd.vue')
      },
      {
        path: '/PirceManagement',
        name: 'PirceManagement',
        component: () => import('../views/DAMA/PirceManagement.vue')
      },
      {
        path: '/RoutecategoryManagement',
        name: 'RoutecategoryManagement',
        component: () => import('../views/DAMA/RoutecategoryManagement.vue')
      },
      {
        path: '/HotelstandardManagement',
        name: 'HotelstandardManagement',
        component: () => import('../views/DAMA/HotelstandardManagement.vue')
      },
      {
        path: '/VendorManagement',
        name: 'VendorManagement',
        component: () => import('../views/DAMA/VendorManagement.vue')
      },
      {
        path: '/CarpriceManagement',
        name: 'CarpriceManagement',
        component: () => import('../views/DAMA/CarpriceManagement.vue')
      },
     
      {
        path: '/NewRouteAdd',
        name: 'NewRouteAdd',
        component: () => import('../views/Quotation/NewRouteAdd.vue')
      },
      {
        path: '/NewRouteManagement',
        name: 'NewRouteManagement',
        component: () => import('../views/Quotation/NewRouteManagement.vue')
      },
      {
        path: '/MaincategoryManagement',
        name: 'MaincategoryManagement',
        component: () => import('../views/DAMA/MaincategoryManagement.vue')
      },
      {
        path: '/SubcategoryManagement',
        name: 'SubcategoryManagement',
        component: () => import('../views/DAMA/SubcategoryManagement.vue')
      },
      {
        path: '/MyitineraryManagement',
        name: 'MyitineraryManagement',
        component: () => import('../views/Quotation/MyitineraryManagement.vue')
      },
      {
        path: '/CarvendorManagement',
        name: 'CarvendorManagement',
        component: () => import('../views/DAMA/CarvendorManagement.vue')
      },
      {
        path: '/ExchangerateManagement',
        name: 'ExchangerateManagement',
        component: () => import('../views/DAMA/ExchangerateManagement.vue')
      },
      {
        path: '/ExchangerateAdd',
        name: 'ExchangerateAdd',
        component: () => import('../views/DAMA/ExchangerateAdd.vue')
      },
      {
        path: '/ExchangerateUpd',
        name: 'ExchangerateUpd',
        component: () => import('../views/DAMA/ExchangerateUpd.vue')
      },
      {
        path: '/MealManagement',
        name: 'MealManagement',
        component: () => import('../views/DAMA/MealManagement.vue')
      },
      {
        path: '/CampItineraryManagement',
        name: 'CampItineraryManagement',
        component: () => import('../views/Quotation/CampItineraryManagement.vue')
      },
      {
        path: '/MyCampItineraryManagement',
        name: 'MyCampItineraryManagement',
        component: () => import('../views/Quotation/MyCampItineraryManagement.vue')
      },
      {
        path: '/CampItineraryAdd',
        name: 'CampItineraryAdd',
        component: () => import('../views/Quotation/CampItineraryAdd.vue')
      },
      {
        path: '/NewRouteUpd',
        name: 'NewRouteUpd',
        component: () => import('../views/Quotation/NewRouteUpd.vue')
      },
      {
        path: '/HotelDataManagement',
        name: 'HotelDataManagement',
        component: () => import('../views/DAMA/HotelDataManagement.vue')
      },
      {
        path: '/CheckOutnew',
        name: 'CheckOutnew',
        component: () => import('../views/Quotation/CheckOutnew.vue')
      },
      {
        path: '/SettlementAll',
        name: 'SettlementAll',
        component: () => import('../views/Quotation/SettlementAll.vue')
      },
      {
        path: '/HotelDataManagementById',
        name: 'HotelDataManagementById',
        component: () => import('../views/DAMA/HotelDataManagementById.vue')
      },
      {
        path: '/TourGuideDate',
        name: 'TourGuideDate',
        component: () => import('../views/DAMA/TourGuideDate.vue')
      },
      {
        path: '/TourGuideDateUpdate',
        name: 'TourGuideDateUpdate',
        component: () => import('../views/DAMA/TourGuideDateUpdate.vue')
      },
      {
        path: '/TourGuideDateAdd',
        name: 'TourGuideDateAdd',
        component: () => import('../views/DAMA/TourGuideDateAdd.vue')
      },
      {
        path: '/TourguidePricesUpd',
        name: 'TourguidePricesUpd',
        component: () => import('../views/DAMA/TourguidePricesUpd.vue')
      },
      {
        path: '/BaseHotelList',
        name: 'BaseHotelList',
        component: () => import('../views/Hotel/BaseHotelList.vue')
      },
      // {
      //   path: '/BaseHotelListPri',
      //   name: 'BaseHotelListPri',
      //   component: () => import('../views/Hotel/BaseHotelListPri.vue')
      // },
      // {
      //   path: '/HotelAPIPriceList',
      //   name: 'HotelAPIPriceList',
      //   component: () => import('../views/Hotel/HotelAPIPriceList.vue')
      // },
      {
        path: '/BaseHotelEdit',
        name: 'BaseHotelEdit',
        component: () => import('../views/Hotel/BaseHotelEdit.vue')
      },
      {
        path: '/IntelQuota',
        name: 'IntelQuota',
        component: () => import('../views/Quotation/IntelQuota.vue')
      },
      {
        path: '/MainBillList',
        name: 'MainBillList',
        component: () => import('../views/Quotation/MainBillList.vue')
      },
      {
        path: '/PictureManagement',
        name: 'PictureManagement',
        component: () => import('../views/DAMA/PictureManagement.vue')
      },
      {
        path: '/PictureManagementAdd',
        name: 'PictureManagementAdd',
        component: () => import('../views/DAMA/PictureManagementAdd.vue')
      },
      {
        path: '/CityManagementAdd',
        name: 'CityManagementAdd',
        component: () => import('../views/DAMA/CityManagementAdd.vue')
      },
      {
        path: '/CityManagementUpd',
        name: 'CityManagementUpd',
        component: () => import('../views/DAMA/CityManagementUpd.vue')
      },
      
      {
        path: '/cityMgrUpdates',
        name: 'cityMgrUpdates',
        component: () => import('../views/DAMA/cityMgrUpdates.vue')
      },
      {
        path: '/CountryManagementUpd',
        name: 'CountryManagementUpd',
        component: () => import('../views/DAMA/CountryManagementUpd.vue')
      },
      {
        path: '/Sight_TimePriceAdd',
        name: 'Sight_TimePriceAdd',
        component: () => import('../views/DAMA/Sight_TimePriceAdd.vue')
      },
      {
        path: '/BasesightsMangement',
        name: 'BasesightsMangement',
        component: () => import('../views/DAMA/BasesightsMangement.vue')
      },
      {
        path: '/MainBillListById',
        name: 'MainBillListById',
        component: () => import('../views/Quotation/MainBillListById.vue')
      },
      {
        path: '/MainBillListEdit',
        name: 'MainBillListEdit',
        component: () => import('../views/Quotation/MainBillListEdit.vue')
      },
      {
        path: '/BasesightsMangementById',
        name: 'BasesightsMangementById',
        component: () => import('../views/DAMA/BasesightsMangementById.vue')
      },
      {
        path: '/HotelPriceList',
        name: 'HotelPriceList',
        component: () => import('../views/Hotel/HotelPriceList.vue')
      },
      {
        path: '/UpdHotelImg',
        name: 'UpdHotelImg',
        component: () => import('../views/Hotel/UpdHotelImg.vue')
      },
      {
        path: '/FlightlistsMangement',
        name: 'FlightlistsMangement',
        component: () => import('../views/DAMA/FlightlistsMangement.vue')
      },
      {
        path: '/ProductInfoList',
        name: 'ProductInfoList',
        component: () => import('../views/Product/ProductInfoList.vue')
      },
      {
        path: '/ProductTypeList',
        name: 'ProductTypeList',
        component: () => import('../views/Product/ProductTypeList.vue')
      },
      {
        path: '/ProductInfos',
        name: 'ProductInfos',
        component: () => import('../views/Product/ProductInfos.vue')
      },
      {
        path: '/ProductDate',
        name: 'ProductDate',
        component: () => import('../views/Product/ProductDate.vue')
      },
      {
        path: '/KYProductsInfo',
        name: 'KYProductsInfo',
        component: () => import('../views/Product/KYProductsInfo.vue')
      },
      {
        path: '/KYProductDetails',
        name: 'KYProductDetails',
        component: () => import('../views/Product/KYProductDetails.vue')
      },
      {
        path: '/DistOrder',
        name: 'DistOrder',
        component: () => import('../views/Product/DistOrder.vue')
      },
      {
        path: '/DistOrderPrice',
        name: 'DistOrderPrice',
        component: () => import('../views/Product/DistOrderPrice.vue')
      },
      {
        path: '/AgentInfoEdit',
        name: 'AgentInfoEdit',
        component: () => import('../views/DAMA/AgentInfoEdit.vue')
      },
      {
        path: '/PayServiceManagement',
        name: 'PayServiceManagement',
        component: () => import('../views/DAMA/PayServiceManagement.vue')
      },
      {
        path: '/PayServiceEdit',
        name: 'PayServiceEdit',
        component: () => import('../views/DAMA/PayServiceEdit.vue')
      },
      {
        path: '/tb_Logs',
        name: 'tb_Logs',
        component: () => import('../views/SysSet/tb_Logs.vue')
      },
      {
        path: '/bocaUserList',
        name: 'bocaUserList',
        component: () => import('../views/SysSet/bocaUserList.vue')
      },
      {
        path: '/changePassword',
        name: 'changePassword',
        component: () => import('../views/SysSet/ChangePassword.vue')
      },
      {
        path: '/tb_jdLogs',
        name: 'tb_jdLogs',
        component: () => import('../views/SysSet/tb_jdLogs.vue')
      },
      
      {
        path: '/articlesHelp',
        name: 'articlesHelp',
        component: () => import('../views/SysSet/articlesHelp.vue')
      },
      {
        path: '/InteAddItinerary',
        name: 'InteAddItinerary',
        component: () => import('../views/Quotation/InteAddItinerary.vue')
      },
      {
        path: '/AirportList',
        name: 'AirportList',
        component: () => import('../views/DAMA/AirportList.vue')
      },
      {
        path: '/AirportUpd',
        name: 'AirportUpd',
        component: () => import('../views/DAMA/AirportUpd.vue')
      },
      {
        path: '/ExhibitList',
        name: 'ExhibitList',
        component: () => import('../views/Hotel/ExhibitList.vue')
      },
      {
        path: '/ExhibitEdit',
        name: 'ExhibitEdit',
        component: () => import('../views/Hotel/ExhibitEdit.vue')
      },
      {
        path: '/AttractionManagementUpd',
        name: 'AttractionManagementUpd',
        component: () => import('../views/DAMA/AttractionManagementUpd.vue')
      },
      {
        path: '/MainBillListByIdRoute',
        name: 'MainBillListByIdRoute',
        component: () => import('../views/Quotation/MainBillListByIdRoute.vue')
      },
      {
        path: '/gysHotelList',
        name: 'gysHotelList',
        component: () => import('../views/Hotel/gysHotelList.vue')
      },
      {
        path: '/gysHotelSetPrice',
        name: 'gysHotelSetPrice',
        component: () => import('../views/Hotel/gysHotelSetPrice.vue')
      },
      {
        path: '/CampsiteSupplierAdd',
        name: 'CampsiteSupplierAdd',
        component: () => import('../views/DAMA/CampsiteSupplierAdd .vue')
      },
      {
        path: '/RoleManagement',
        name: 'RoleManagement',
        component: () => import('../views/Tree/RoleManagement.vue')
      },
      {
        path: '/editClientInfo',
        name: 'editClientInfo',
        component: () => import('../views/SysSet/editClientInfo.vue')
      },
      {
        path: '/clientAdd',
        name: 'clientAdd',
        component: () => import('../views/SysSet/clientAdd.vue')
      },
      
      {
        path: '/ChildClientList',
        name: 'ChildClientList',
        component: () => import('../views/SysSet/ChildClientList.vue')
      },
      
      {
        path: '/addChildClient',
        name: 'addChildClient',
        component: () => import('../views/SysSet/addChildClient.vue')
      },
      {
        path: '/childClientUpdate',
        name: 'childClientUpdate',
        component: () => import('../views/SysSet/childClientUpdate.vue')
      },
      {
        path:'/CommissionRate',
        name:'CommissionRate',
        component:()=>import('../views/SysSet/CommissionRate.vue')
      },
      {
        path:'/CommissionRateUpd',
        name:'CommissionRateUpd',
        component:()=>import('../views/SysSet/CommissionRateUpd.vue')
      },
      {
        path: '/RenewalOfPayment',
        name: 'RenewalOfPayment',
        component: () => import('../views/SysSet/RenewalOfPayment.vue')
      },
      {
        path: '/TbTrainPrices',
        name: 'TbTrainPrices',
        component: () => import('../views/APIInterfaceManagement/TbTrainPrices.vue')
      },
      {
        path: '/broadWayInfo',
        name: 'broadWayInfo',
        component: () => import('../views/APIInterfaceManagement/broadWayInfo.vue')
      },
      {
        path: '/broadWayDetail',
        name: 'broadWayDetail',
        component: () => import('../views/APIInterfaceManagement/broadWayDetail.vue')
      },
      {
        path: '/broadWayUpdate',
        name: 'broadWayUpdate',
        component: () => import('../views/APIInterfaceManagement/broadWayUpdate.vue')
      },
      {
        path: '/RoleManagementAdd',
        name: 'RoleManagementAdd',
        component: () => import('../views/Tree/RoleManagementAdd.vue')
      },
      {
        path: '/MenuManagement',
        name: 'MenuManagement',
        component: () => import('../views/Tree/MenuManagement.vue')
      },
      {
        path: '/MenuManagementAdd',
        name: 'MenuManagementAdd',
        component: () => import('../views/Tree/MenuManagementAdd.vue')
      },
      {
        path: '/UserManagement',
        name: 'UserManagement',
        component: () => import('../views/Tree/UserManagement.vue')
      },
      {
        path: '/UserManagementAdd',
        name: 'UserManagementAdd',
        component: () => import('../views/Tree/UserManagementAdd.vue')
      },
      {
        path: '/ApplyCheckIn',
        name: 'ApplyCheckIn',
        component: () => import('../views/Tree/ApplyCheckIn.vue')
      },
      
      {
        path: '/aiMessages',
        name: 'aiMessages',
        component: () => import('../views/Tree/aiMessages.vue')
      },
      {
        path: '/ItineraryManagementExternal',
        name: 'ItineraryManagementExternal',
        component: () => import('../views/Quotation/ItineraryManagementExternal.vue')
      },
      {
        path: '/CarpriceManagementSelectServeCity',
        name: 'CarpriceManagementSelectServeCity',
        component: () => import('../views/DAMA/CarpriceManagementSelectServeCity.vue')
      },
      {
        path: '/MyCampItineraryManagementExport',
        name: 'MyCampItineraryManagementExport',
        component: () => import('../views/Quotation/MyCampItineraryManagementExport.vue')
      },
      {
        path: '/ItineraryManagementExport',
        name: 'ItineraryManagementExport',
        component: () => import('../views/Quotation/ItineraryManagementExport.vue')
      },
      {
        path: '/exportImages',
        name: 'exportImages',
        component: () => import('../views/Quotation/exportImages.vue')
      },
      {
        path: '/ItineraryBook',
        name: 'ItineraryBook',
        component: () => import('../views/Quotation/ItineraryBook.vue')
      },
      {
        path: '/ItinerarySpeedAdd',
        name: 'ItinerarySpeedAdd',
        component: () => import('../views/Quotation/ItinerarySpeedAdd.vue')
      },
      {
        path: '/SystemSet',
        name: 'SystemSet',
        component: () => import('../views/SysSet/SystemSet.vue')
      },
      {
        path: '/ConfirmOrder',
        name: 'ConfirmOrder',
        component: () => import('../views/Quotation/ConfirmOrder.vue')
      },
      {
        path: '/GYSConfirmOrder',
        name: 'GYSConfirmOrder',
        component: () => import('../views/Quotation/GYSConfirmOrder.vue')
      },
      {
        path: '/ApplyGroupNum',
        name: 'ApplyGroupNum',
        component: () => import('../views/Quotation/ApplyGroupNum.vue')
      },
      {
        path: '/AirTicketAPIB2B',
        name: 'AirTicketAPIB2B',
        component: () => import('../views/APIInterfaceManagement/AirTicketAPIB2B.vue')
      },
      {
        path: '/ShipCompany',
        name: 'ShipCompany',
        component: () => import('../views/Cruise/ShipCompany.vue')
      },
      {
        path: '/ShipCompanyAdd',
        name: 'ShipCompanyAdd',
        component: () => import('../views/Cruise/ShipCompanyAdd.vue')
      },
      {
        path: '/ShipCompanyUpd',
        name: 'ShipCompanyUpd',
        component: () => import('../views/Cruise/ShipCompanyUpd.vue')
      },
      {
        path: '/ShipInfo',
        name: 'ShipInfo',
        component: () => import('../views/Cruise/ShipInfo.vue')
      },
      {
        path: '/ShipInfoAdd',
        name: 'ShipInfoAdd',
        component: () => import('../views/Cruise/ShipInfoAdd.vue')
      },
      {
        path: '/ShipInfoUpd',
        name: 'ShipInfoUpd',
        component: () => import('../views/Cruise/ShipInfoUpd.vue')
      },
      {
        path: '/ShipRoute',
        name: 'ShipRoute',
        component: () => import('../views/Cruise/ShipRoute.vue')
      },
      {
        path: '/RestaurantInfo',
        name: 'RestaurantInfo',
        component: () => import('../views/DAMA/RestaurantInfo.vue')
      },
      {
        path: '/RestaurantInfoAdd',
        name: 'RestaurantInfoAdd',
        component: () => import('../views/DAMA/RestaurantInfoAdd.vue')
      },
      {
        path: '/RestaurantInfoUpd',
        name: 'RestaurantInfoUpd',
        component: () => import('../views/DAMA/RestaurantInfoUpd.vue')
      },
      
      {
        path: '/RestaurantUps',
        name: 'RestaurantUps',
        component: () => import('../views/DAMA/RestaurantUps.vue')
      },
      {
        path: '/FeiZhuTickets',
        name: 'FeiZhuTickets',
        component: () => import('../views/DAMA/FeiZhuTickets.vue')
      },
      {
        path: '/FerrieList',
        name: 'FerrieList',
        component: () => import('../views/Cruise/FerrieList.vue')
      },
      {
        path: '/FerrieEdit',
        name: 'FerrieEdit',
        component: () => import('../views/Cruise/FerrieEdit.vue')
      },
      {
        path: '/AirTicketAPIB2BOperation',
        name: 'AirTicketAPIB2BOperation',
        component: () => import('../views/APIInterfaceManagement/AirTicketAPIB2BOperation.vue')
      },
      {
        path: '/tb_ymsms',
        name: 'tb_ymsms',
        component: () => import('../views/SysSet/tb_ymsms.vue')
      },
      {
        path: '/userCode',
        name: 'userCode',
        component: () => import('../views/ctripMake/userCode.vue')
      },
      {
        path: '/ExhibitForLine',
        name: 'ExhibitForLine',
        component: () => import('../views/Hotel/ExhibitForLine.vue')
      },
      {
        path: '/ctripOrderList',
        name: 'ctripOrderList',
        component: () => import('../views/ctripMake/ctripOrderList.vue')
      },
      {
        path: '/orderDetails',
        name: 'orderDetails',
        component: () => import('../views/ctripMake/orderDetails.vue')
      },
      {
        path: '/bocaUserUpdate',
        name: 'bocaUserUpdate',
        component: () => import('../views/SysSet/bocaUserUpdate.vue')
      },
      {
        path: '/PackageLdp',
        name: 'PackageLdp',
        component: () => import('../views/Product/PackageLdp.vue')
      },
      {
        path: '/ProductInfo',
        name: 'ProductInfo',
        component: () => import('../views/Product/ProductInfo.vue')
      },
      {
        path: '/ProductExport',
        name: 'ProductExport',
        component: () => import('../views/Product/ProductExport.vue')
      },
      {
        path: '/ProductAdd',
        name: 'ProductAdd',
        component: () => import('../views/Product/ProductAdd.vue')
      },
      {
        path: '/ProductEditPrice',
        name: 'ProductEditPrice',
        component: () => import('../views/Product/ProductEditPrice.vue')
      },
      {
        path: '/CampCoursesExport',
        name: 'CampCoursesExport',
        component: () => import('../views/Product/CampCoursesExport.vue')
      },
      {
        path: '/ItineraryIntelAdd',
        name: 'ItineraryIntelAdd',
        component: () => import('../views/Quotation/ItineraryIntelAdd.vue')
      },
      {
        path: '/CanyouList',
        name: 'CanyouList',
        component: () => import('../views/APIInterfaceManagement/CanyouList.vue')
      },
      {
        path: '/CanyouDetail',
        name: 'CanyouDetail',
        component: () => import('../views/APIInterfaceManagement/CanyouDetail.vue')
      },
      {
        path: '/CanyouUpdate',
        name: 'CanyouUpdate',
        component: () => import('../views/APIInterfaceManagement/CanyouUpdate.vue')
      },
      {
        path:'/GalaxyInfos',
        name:'GalaxyInfos',
        component:()=>import('../views/Galaxy/GalaxyInfos.vue')
      },
      {
        path:'/GalaxyInfoDetail',
        name:'GalaxyInfoDetail',
        component:()=>import('../views/Galaxy/GalaxyInfoDetail.vue')
      },
      {
        path:'/orderManage',
        name:'orderManage',
        component:()=>import('../views/ctripMake/orderManage.vue')
      },
      {
        path:'/orderInfo',
        name:'orderInfo',
        component:()=>import('../views/ctripMake/orderInfo.vue')
      },
      {
        path:'/kayOrderDetail',
        name:'kayOrderDetail',
        component:()=>import('../views/ctripMake/kayOrderDetail.vue')
      },
      {
        path:'/orderExport',
        name:'orderExport',
        component:()=>import('../views/ctripMake/orderExport.vue')
      },
      {
        path:'/CustomManage',
        name:'CustomManage',
        component:()=>import('../views/Quotation/CustomManage.vue')
      },
      {
        path:'/ActivityInfos',
        name:'ActivityInfos',
        component:()=>import('../views/Klktech/ActivityInfos.vue')
      },
      {
        path:'/ActivityDetails',
        name:'ActivityDetails',
        component:()=>import('../views/Klktech/ActivityDetails.vue')
      },
      {
        path:'/HotelOrderManage',
        name:'HotelOrderManage',
        component:()=>import('../views/Hotel/HotelOrderManage.vue')
      },
      {
        path:'/HotelOrderInfo',
        name:'HotelOrderInfo',
        component:()=>import('../views/Hotel/HotelOrderInfo.vue')
      },
      {
        path:'/HotelExport',
        name:'HotelExport',
        component:()=>import('../views/Hotel/HotelExport.vue')
      },
      {
        path:'/TicketOrderManage',
        name:'TicketOrderManage',
        component:()=>import('../views/Ticket/TicketOrderManage.vue')
      },
      {
        path:'/TicketOrderInfo',
        name:'TicketOrderInfo',
        component:()=>import('../views/Ticket/TicketOrderInfo.vue')
      },
      {
        path:'/TicketBroadwayOrderInfo',
        name:'TicketBroadwayOrderInfo',
        component:()=>import('../views/Ticket/TicketBroadwayOrderInfo.vue')
      },
      {
        path:'/GrangdCanyouOrderInfo',
        name:'GrangdCanyouOrderInfo',
        component:()=>import('../views/Ticket/GrangdCanyouOrderInfo.vue')
      },
      {
        path:'/TicketOrderExport',
        name:'TicketOrderExport',
        component:()=>import('../views/Ticket/TicketOrderExport.vue')
      },
      {
        path:'/ActivityEdit',
        name:'ActivityEdit',
        component:()=>import('../views/Klktech/ActivityEdit.vue')
      },
      {
        path:'/tbGyHotelpri',
        name:'tbGyHotelpri',
        component:()=>import('../views/Hotel/tbGyHotelpri.vue')
      },
      {
        path:'/HotelApiJson',
        name:'HotelApiJson',
        component:()=>import('../views/Hotel/HotelApiJson.vue')
      },
      {
        path: '/SightSku',
        name: 'SightSku',
        component: () => import('../views/DAMA/SightSku.vue')
      },
      {
        path: '/clientinfoList',
        name: 'clientinfoList',
        component: () => import('../views/SysSet/clientinfoList.vue')
      },
      {
        path: '/clientUpdate',
        name: 'clientUpdate',
        component: () => import('../views/SysSet/clientUpdate.vue')
      },
      {
        path: '/SaasProducts',
        name: 'SaasProducts',
        component: () => import('../views/SysSet/SaasProducts.vue')
      },
    ],
  },
  {
    path: '/AgentRequest',
    name: 'AgentRequest',
    component: () => import('../views/Tree/AgentRequest.vue')
  },
  {
    path: '/SupplierRequest',
    name: 'SupplierRequest',
    component: () => import('../views/Tree/SupplierRequest.vue')
  },
  {
    path: '/MISRequest',
    name: 'MISRequest',
    component: () => import('../views/Tree/MISRequest.vue')
  },
  {
    path: '/H5Detail',
    name: 'H5Detail',
    component: () => import('../views/Quotation/H5Detail.vue')
  },  

  {
    path: '/FlightSearch',
    name: 'FlightSearch',
    component: () => import('../views/Flight/FlightSearch.vue')
  },
  {
    path: '/packageInfo',
    name: 'packageInfo',
    component: () => import('../views/Quotation/PackageInfo.vue')
  },

]

const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes: routes,
})

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  if (isChunkLoadFailed) {
    // 用路由的replace方法，并没有相当于F5刷新页面，失败的js文件并没有从新请求，会导致一直尝试replace页面导致死循环，而用 location.reload 方法，相当于触发F5刷新页面，虽然用户体验上来说会有刷新加载察觉，但不会导致页面卡死及死循环，从而曲线救国解决该问题
    location.reload();
    // const targetPath = $router.history.pending.fullPath;
    // $router.replace(targetPath);
  }
});

router.beforeEach((to, from, next) => { //路由守卫
  if(to.name != 'MainMenu'){ // 隐藏统计首页
    var node = document.getElementById('statisticsView');
    if(node){
      node.style.display = 'none'
    }
  }
  const needSessionStorage = window.location.href.includes("sessionStorage=true");
  // 判断是否是登录页面 
  if (to.path === '/' || to.name === 'LoGin' || to.name === 'H5Detail' || to.name === 'AgentRequest' || to.name === 'SupplierRequest' || to.name === 'MISRequest' || to.name === 'ApplyCheckIn' || needSessionStorage) {
    // 登录页面不需要路由守卫，直接执行下一个导航钩子
    next();
  } else {
    // 在这里编写需要进行路由守卫的逻辑 
    // 执行下一个导航钩子 
    if (sessionStorage.getItem('loginDate') && sessionStorage.getItem('userid') && sessionStorage.getItem('loginDate') > new Date()) {
      next();
    } else {
      alert('请先登录')
      router.push({ name: 'LoGin' });
      next(false)
    }
  }
});

//拦截 添加token
axios.interceptors.request.use(
  config => {
    if (localStorage.token) {
      config.headers.Authorization = "Bearer " + localStorage.token
    }
    config.headers['Access-Control-Allow-Origin'] = '*';
    return config
  },
  err => {
    return Promise.reject(err);
  }
);


export default router
