<template>
  <div id="mainbody">
    <div class="box">
      <div>
        <ul  style="list-style: none;color: white;border: 1px solid transparent; width: 900px;font-size: 20px;margin-left: 50px;margin-bottom: 250px;text-align: left;">
          <li> <h1> TMS管理系统（Travel Management System）</h1></li>
          <li  style="margin-top: 100px; margin-left:30px "> <h4> 一款创新的旅游定制报价系统，无需复杂操作就可以实现实时快速报价！</h4></li>
          <li  style="margin-left:40px ;"> <h4>智能行程 &nbsp;&nbsp; 	支持多种智能制作行程方案，实时数据支撑！（实时航班，实时酒店）</h4></li>
          <li  style="margin-left:40px ;">  <h4>智能报价 &nbsp;&nbsp;  	10秒钟摆脱手工操作！一次自动制出8种报价方案！</h4></li>
          <li  style="margin-left:40px ;"> <h4>智能输出 &nbsp;&nbsp; 	支持行程和报价结果多种输出方式，H5,PDF,DOC, PICTURE,EXCEL等方式</h4></li>
        </ul>
      </div>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span class="login-title"> 登录  </span>
        </div>
        <!--🔐 美大内部报价系统 -->
        <el-form ref="loginForm" :model="loginForm" status-icon :rules="loginRules" label-position="top"
          label-width="100px" class="demo-ruleForm">
          <el-form-item label="账号" style="text-align: left;" prop="username">
            <el-input type="text" v-model="loginForm.username" autocomplete="off"   placeholder="账号/邮箱/手机号"  ></el-input>
          </el-form-item>
          <el-form-item label="密码" style="text-align: left;" prop="Password">
            <el-input type="password" v-model="loginForm.Password" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="验证码" style="text-align: left;" prop="code"  >
    <el-row type="flex" align="middle">
      <el-col :span="12">
        <el-input  style="border: 1px solid transparent;width: 180px;" type="text" v-model="loginForm.code" autocomplete="off" ></el-input>
      </el-col>
      <el-col :span="12">
        <div class="login-code" @click="refreshCode">
          <SIdentify :identifyCode="identifyCode" />
        </div>
      </el-col>
    </el-row>
  </el-form-item>
          <el-form-item >
            <el-button type="primary" @click="login" style="position: absolute;left:40%;">登录</el-button>
            <!-- <el-link type="primary"  @click="ForgotPassword" style="position: relative;left:200px;">忘记密码</el-link>  -->
            <el-link type="primary"  @click="applyCheckIn"   style="margin-left:53%;position: relative;" >申请入驻</el-link>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import SIdentify from "../../components/SIdentify.vue";
export default {
  components: {
    SIdentify,
  },
  data() {
    return {
      loginForm: {
        username: "",
        Password: "",
        code: "",
      },
      loginRules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        Password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      identifyCodes: '1234567890abcdefjhijklinopqrsduvwxyz',
      identifyCode: '',
    };
  },
  mounted() {
    this.identifyCode = '';
    this.makeCode(this.identifyCodes, 4);
  },
  methods: {
    refreshCode() {
      this.identifyCode = '';
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    login() {
      if (this.loginForm.code.toLowerCase() !== this.identifyCode.toLowerCase()) {
        this.$message.error('请填写正确验证码');
        this.refreshCode();
        return;
      }
      this.url = this.$apiBaseUrl + "api/User/LoginNew";
      const data = {
        name: this.loginForm.username,
        Password: this.loginForm.Password,
      };
      this.$axios.post(this.url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        if(  response.data=="您的账号已到期，请联系相关人员激活") {
          this.$message.error("您的账号已到期，请联系相关人员激活!!!");
          return;
        }
        if (response.data != 0) {
          sessionStorage.setItem("roleId", response.data.roleId);
          sessionStorage.setItem("userType", response.data.userType);
          this.indata(); //获取系统设置
          this.$message.success("账号" + this.loginForm.username + "登录成功");
          localStorage.token = response.data.token;
          let loginDate = new Date();
          sessionStorage.setItem("loginDate", loginDate.setDate(loginDate.getDate() + 1));
          sessionStorage.setItem("userid", response.data.id);
          sessionStorage.setItem("username", this.loginForm.username);
          sessionStorage.setItem('RequestType', response.data.userType); //设置登录人类型
          if (sessionStorage.getItem('userid')) {
            this.$router.push("/MainMenu");
          }
        }
        else {
          this.$message.error("登录失败,账号或密码错误!!!");
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    indata() {
      this.url = this.$apiBaseUrl + "api/User/GetSystemSetInfo";
      this.$axios.get(this.url).then(response => {
        sessionStorage.setItem("SystemSettings", JSON.stringify(response.data)); //存系统设置
      })
    },
    applyCheckIn(){
      this.$router.push('/ApplyCheckIn')
    },

    // ForgotPassword(){
    //   this.$router.push('/RetrievePassword')
    // }
  },
};
</script>


<style scoped lang="less">
#mainbody {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/loginBg.jpg');
  background-size: cover;
  background-position: center;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.login-container {
  width: 30%;
  max-width: 600px;
  /* 设置最大宽度 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  padding: 20px;
}

.login-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
}

.el-form-item {
  margin-bottom: 10px;
  /* 调整元素间距 */
}

.inputBig {
  height: 30px;
  /* 输入框高度 */
  font-size: 16px;
  /* 输入框字体大小 */
}

.yanzhengma .el-input__inner {
  width: 50%;
  /* 验证码输入框宽度 */
  height: 40px;
  /* 验证码输入框高度 */
  font-size: 16px;
  /* 验证码输入框字体大小 */
}

.login-code {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  /* 验证码区域宽度 */
  position: absolute;
  right: 0;
  top: -10px;

}

/* 调整验证码画布的大小 */
.s-canvas canvas {
  width: 120px;
  /* 根据需要调整宽度 */
  height: 40px;
  /* 根据需要调整高度 */
}

.box-card {
  width: 434.719px;
  height: 480.062px;
  margin-left: auto;
  margin-right: auto;
}


.verification-code-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.verification-input {
  flex: 1;
  margin-right: 10px; /* 添加一些间距 */
}

.login-code {
  flex: 1;
  cursor: pointer;
  /* 可以添加一些样式来美化验证码图片的容器 */
}

/* 调整验证码画布的大小 */
.s-canvas canvas {
  width: 100%; /* 让验证码图片填满容器 */
  height: auto;
}
</style>